@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes progress {
  0% {
    right: 0;
  }

  100% {
    right: 100%;
  }
}

@keyframes pulseEffect {

  0%,
  100% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 0% 50%;
  }
}